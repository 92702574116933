
import { Options } from "vue-class-component";
import NodesMinxins from "../NodesMixins";

@Options({
    name: "flowable-nodes-delay-node",
    emits: ["selected", "delNode", "insertNode"],
})
export default class DelayNode extends NodesMinxins {
    private showError = false;
    private errorInfo = "";

    get content() {
        if (this.config.props.type === "FIXED") {
            return `等待 ${this.config.props.time} ${this.getName(this.config.props.unit)}`;
        } else if (this.config.props.type === "AUTO") {
            return `至当天 ${this.config.props.dateTime}`;
        } else {
            return null;
        }
    }
    //校验数据配置的合法性
    public validate(err: any) {
        this.showError = false;
        try {
            if (this.config.props.type === "AUTO") {
                if ((this.config.props.dateTime || "") === "") {
                    this.showError = true;
                    this.errorInfo = "请选择时间点";
                }
            } else {
                if (this.config.props.time <= 0) {
                    this.showError = true;
                    this.errorInfo = "请设置延时时长";
                }
            }
        } catch (e) {
            this.showError = true;
            this.errorInfo = "配置出现问题";
        }
        if (this.showError) {
            err.push(`${this.config.name} 未设置延时规则`);
        }
        return !this.showError;
    }
    public getName(unit: any) {
        switch (unit) {
            case "D":
                return "天";
            case "H":
                return "小时";
            case "M":
                return "分钟";
            default:
                return "未知";
        }
    }
}
